#tabs{
    display: block;
    ul{
        display: block;
        width: 100%;
        height: 50px;
        padding: 0;
        margin: 0;
        border-bottom: 1px solid #ddd;
        li.tabMenu{
            box-sizing: border-box;
            float: left;
            list-style: none;
            padding: 0 1.2rem;
            height: 50px;
            line-height: 50px;
            cursor: pointer;
            border: 1px solid #ddd;
            background-color: #f3f3f3;
            color: #999;
            &.active{
                display: inline;
                list-style: none;
                border-top: 1px solid #007bff;
                border-bottom: 1px solid #fff;
                background-color: #fff;
                color: #007bff;
            }
        }
    }

    .tabItem{
        display: none;
        background-color: #fff;
        padding: 1rem;
        &.active{
            display: block;
        }
    }
}